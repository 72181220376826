  <template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Tambah Komplain Warga" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">No Komplain</label>
            <vs-input class="w-full" v-model="data.no_komplain" placeholder="Generate Otomatis" disabled/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Tgl. Komplain *</label>
            <flat-pickr class="w-full" v-model="data.tgl_komplain"></flat-pickr>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Proyek *</label>
            <vs-select class="w-full" v-model="data.id_proyek">
              <vs-select-item v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="`${it.kode} - ${it.nama}`"/>
            </vs-select>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Properti Unit *</label>
            <vs-select class="w-full" v-model="data.id_properti_unit" :disabled="!data.id_proyek">
              <vs-select-item v-for="(it, index) in propertiUnits" :key="index" :value="it.id" :text="it.no_unit"/>
            </vs-select>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Atas Nama *</label>
            <vs-input class="w-full" icon-pack="feather" icon="icon-user" placeholder="Atas Nama *" v-model="data.atas_nama"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Telp *</label>
            <vs-input class="w-full" icon-pack="feather" icon="icon-phone" placeholder="Telp *" v-model="data.telp"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Email</label>
            <vs-input class="w-full" icon-pack="feather" icon="icon-mail" placeholder="E-mail" v-model="data.email"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <label class="ml-1 text-xs">Keterangan Komplain *</label>
            <vs-textarea class="w-full" v-model="data.keterangan"/>
          </div>
        </div>

        <vs-divider> Data Follow Up </vs-divider>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="flex flex-wrap justify-between items-center mb-1">
              <div class="mb-4 md:mb-0 mr-4">
                <div class="flex items-center">
                  <vs-button color="success" icon-pack="feather" icon="icon-plus" @click="addRow" class="py-2 px-3 mr-2">Add</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <vs-table :data="data.details" stripe>
          <template slot="thead">
            <vs-th class="whitespace-no-wrap">Aksi</vs-th>
            <vs-th class="whitespace-no-wrap">#</vs-th>
            <vs-th class="whitespace-no-wrap">Status *</vs-th>
            <vs-th class="whitespace-no-wrap">Ditangani Oleh *</vs-th>
            <vs-th class="whitespace-no-wrap">Tgl. Handling *</vs-th>
            <vs-th class="whitespace-no-wrap">Tgl. Selesai</vs-th>
            <vs-th class="whitespace-no-wrap">File Pendukung *</vs-th>
            <vs-th class="whitespace-no-wrap">Keterangan *</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
              <vs-td>
                <vs-button color="danger" class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-x" size="small" @click="removeRow(item.uuid)"/>
              </vs-td>
              <vs-td class="whitespace-no-wrap">Follow up - {{ index + 1 }}</vs-td>
              <vs-td>
                <vs-select v-model="item.status">
                  <vs-select-item  v-for="(it, index) in ['PROSES', 'SELESAI', 'TUTUP']" :key="index" :value="it" :text="it"/>
                </vs-select>
              </vs-td>
              <vs-td>
                <div @click="chooseStaf(item.uuid)">
                  <vx-input-group>
                    <vs-input class="w-48" placeholder="Nama Staf" :value="item.nama_staf" readonly/>
                    <template slot="append">
                      <div class="append-text btn-addon">
                        <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                      </div>
                    </template>
                  </vx-input-group>
                </div>
              </vs-td>
              <vs-td><flat-pickr class="w-32" v-model="item.tgl_handling"></flat-pickr></vs-td>
              <vs-td><flat-pickr class="w-32" v-model="item.tgl_selesai"></flat-pickr></vs-td>
              <vs-td>
                <vs-input class="w-64" type="file" accept=".jpeg,.jpg,.png,.pdf" multiple v-model="item.filesTmp" @change="item.files = $event.target.files"/>
              </vs-td>
              <vs-td><vs-input class="w-48" type="text" v-model="item.keterangan"/></vs-td>
            </vs-tr>
          </template>
        </vs-table>

        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

      <!--modal staf-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih Staf"
                :active="modalStaf.active"
                v-on:update:active="modalStaf.active = $event">
        <Staf v-if="!isFirstActive" :selectable="true" :externalFilter="filterStaf" @selected="onSelectedModalStaf"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import KomplainWargaRepository from '@/repositories/estate/komplain-warga-repository'
import ProyekRepository from '@/repositories/master/proyek-repository'
import Staf from '@/views/pages/master/staf/Staf'
import PropertiUnitRepository from '@/repositories/master/properti-unit-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { convertToFormData } from '@/utilities/common/global-methods'

export default {
  name: 'KomplainWargaAdd',
  props: ['isActive'],
  components: {
    ValidationErrors,
    Staf,
    flatPickr
  },
  data () {
    return {
      isFirstActive: true,
      isLoading: false,
      errors: null,
      proyeks: [],
      propertiUnits: [],
      modalStaf: {
        rowUuid: null,
        active: false
      },
      data: {
        id_proyek: null,
        id_properti_unit: null,
        tgl_komplain: moment().format('YYYY-MM-DD'),
        details: []
      }
    }
  },
  computed: {
    filterStaf () {
      return { id_proyek: this.data.id_proyek }
    }
  },
  watch: {
    isActive (active) {
      if (active && this.isFirstActive) {
        this.getProyek()
        this.isFirstActive = false
      }
    },
    'data.id_proyek' (newVal, oldVal) {
      if (newVal && !_.isEqual(newVal, oldVal)) {
        this.onChangeProyek()
      }
    }
  },
  methods: {
    onChangeProyek () {
      this.propertiUnits = []
      this.data.id_properti_unit = null
      if (this.data.id_proyek) this.getPropertiUnit()
    },

    getProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getPropertiUnit () {
      const params = {
        filter: JSON.stringify({
          id_proyek: this.data.id_proyek
        })
      }
      PropertiUnitRepository.getAll(params)
        .then(response => {
          this.propertiUnits = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    chooseStaf (rowUuid) {
      this.modalStaf.rowUuid = rowUuid
      this.modalStaf.active = true
    },

    onSelectedModalStaf (data) {
      const uuid = this.modalStaf.rowUuid
      const index = _.findIndex(this.data.details, item => item.uuid === uuid)
      this.data.details[index].id_staf = data.id
      this.data.details[index].nama_staf = data.nama
      this.modalStaf.active = false
    },

    addRow () {
      const row = {
        uuid: uuid(),
        fileTmp: ''
      }
      this.data.details.push(row)
    },

    removeRow (uuid) {
      const index = _.findIndex(this.data.details, item => item.uuid === uuid)
      this.data.details.splice(index, 1)
    },

    save () {
      this.errors = null
      this.isLoading = true

      const params = convertToFormData(this.data)
      KomplainWargaRepository.create(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['proyeks'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
